body {
  color: #ffffff;
  font-family: "Playfair Display", serif !important;
  font-style: italic;
  background-image: url("./assets/images/bkg-op40-sm.png");
  background-attachment: fixed;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
a {
  text-decoration: none !important;
}
a:hover {
  color: rgba(135, 22, 62, 0.7) !important;
}
.opac-60 {
  opacity: 0.6;
}
.opac-100 {
  opacity: 1;
}
ul.seed {
  list-style-image: url("./assets/images/seed.png") !important;
  text-align: justify;
}
.fixed-center {
  position: fixed;
  top: 30%;
  left: 50%;
}
.center-left {
  top: 50%;
  left: 2%;
}
.center-right {
  top: 50%;
  right: 2%;
}
.font-large {
  font-size: 2.5rem;
}
.font-style-normal {
  font-style: normal;
}
.font-admin {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif !important;
}
.spec-font {
  font-family: Srisakdi-Regular;
}
.spec-font-bold {
  font-family: Srisakdi-Bold;
}
.text-red {
  color: #87163e;
}
.text-red-light {
  color: rgba(135, 22, 62, 0.7);
}
.text-coffee {
  color: #3d2500;
}
.text-blue {
  color: #0c004a;
}
.text-blue-light {
  color: rgba(12, 0, 74, 0.7);
}
.text-pink {
  color: rgb(212, 190, 187) !important;
}
.text-pink:hover {
  color: rgb(211, 167, 161) !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-red {
  background-color: #87163e !important;
}
.bg-pink-grad {
  background-image: radial-gradient(
    circle,
    rgba(217, 184, 180, 0.09),
    rgba(234, 212, 210, 0.5),
    rgba(255, 255, 255, 0.05)
  );
}
.bg-pink {
  background-color: rgba(252, 244, 243, 0.4);
}
.bg-dark-pink {
  background-color: rgba(252, 244, 243, 1) !important;
}
.bg-image {
  background-attachment: local;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.bg-image.h-20 {
  height: 20vh;
}
.bg-image.h-30 {
  height: 30vh;
}
.bg-image.h-60 {
  height: 60vh;
}
.bg-image-nav {
  background-attachment: fixed;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url("./assets/images/bkg-lessSat-sm.png");
}
.bg-image-nav.opacity {
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-image.hover {
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff !important;
}
.bg-image.hover:hover {
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.6);
  color: #87163e !important;
}
.pressed-btn:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.hover:hover .black-n-white-hover {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.display-on-hover {
  color: transparent !important;
}
.hover:hover .display-on-hover {
  color: #87163e !important;
}
.navbar-red.navbar .nav-link {
  color: rgba(83, 16, 39, 0.8);
}
.navbar-red.navbar .nav-link:hover {
  color: rgba(135, 22, 62, 0.7) !important;
}
.navbar-red .navbar-brand {
  color: rgba(135, 22, 62, 0.9);
}
.navbar-red.navbar .nav-link.active {
  color: rgba(135, 22, 62, 1);
  /* text-decoration: underline !important; */
  border-bottom: solid 1px #87163e;
}
.navbar-coffee.navbar .nav-link {
  color: rgba(61, 37, 0, 0.5);
}
.navbar-coffee.navbar .nav-link:hover {
  color: rgba(61, 37, 0, 0.8) !important;
}
.navbar-coffee .navbar-brand {
  color: rgba(61, 37, 0, 0.9);
}
.navbar-coffee.navbar .nav-link.active {
  color: rgba(61, 37, 0, 1);
}
.btn.btn-outline-red {
  color: rgba(83, 16, 39, 0.6);
  border-color: rgba(83, 16, 39, 0.5);
}
.disabled-custom {
  color: #6c757d;
  pointer-events: none;
  background-color: rgba(243, 243, 243, 0.6);
}
.btn.btn-outline-red:hover {
  color: rgba(135, 22, 62, 0.9);
  border-color: rgba(135, 22, 62, 0.2);
}
.btn.btn-red {
  color: #ffffff;
  border-color: rgba(135, 22, 62, 0.9);
  background-color: rgba(135, 22, 62, 0.9);
}
.btn.btn-red:hover {
  color: #ffffff;
  border-color: #87163e;
  background-color: #87163e;
}
.active.about-us {
  font-size: xx-large;
}
.drop-down-search {
  position: relative;
}
.drop-down-search .options-div {
  width: 100%;
  position: absolute;
  z-index: 100;
  max-height: 50vh;
  overflow-y: auto;
  background-color: white;
}

.drop-down-search a {
  white-space: pre;
  z-index: 100;
  height: 30px;
  padding: 4px 8px;
}
.list-group-item.active {
  background-color: #87163e !important;
  border-color: #87163e !important;
}
.list-group-item.active:hover {
  background-color: rgba(135, 22, 62, 0.1) !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
  color: #87163e !important;
}
.list-group-item:hover {
  background-color: rgba(135, 22, 62, 0.1) !important;
}
.dropdown-menu .dropdown-item:first-child {
  display: none;
}
.dropdown-item-faded-color {
  color: rgba(0, 0, 0, 0.6);
}
.dropdown-item:hover {
  color: #6c757d;
  background-color: rgba(135, 22, 62, 0.1) !important;
}
.dropdown-menu.red {
  min-width: 3rem !important;
  border-color: rgba(135, 22, 62, 0.2);
}

.dropdown-item.red {
  color: #87163e;
  border-color: rgba(135, 22, 62, 0.2);
}

textarea:focus,
.dropdown-btn:focus,
input[type="text"]:focus,
input[type="email"]:focus,
video:focus {
  outline: none !important;
  border-color: rgba(135, 22, 62, 0.5) !important;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(135, 22, 62, 0.6) !important;
}
.max-h-50 {
  max-height: 50vh;
}
.min-h-90 {
  min-height: 90vh;
}
.min-h-40 {
  min-height: 40vh;
}
.min-h-15 {
  min-height: 15%;
}
.min-h-150 {
  min-height: 150px;
}
.vh-50 {
  height: 50vh;
}
.size-100 {
  height: 100vh;
  width: 100%;
}

.size-50 {
  height: 50vh;
  width: 100%;
}
.page-alt {
  margin-top: 55px;
  min-height: 100vh;
}

.page {
  margin: 230px auto 16px auto;
  min-height: 80vh;
}

.page.home {
  margin-top: 0;
  min-height: 100vh;
}
.arrow {
  border: solid #ffffff;
  border-width: 3px;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  display: inline-block;
  padding: 3px;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 6px;
  margin-bottom: -5px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.underline {
  position: relative;
}
.underline.top:before {
  background: -moz-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: -webkit-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: -ms-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  border-radius: 0.2em;
  top: 0;
  content: "";
  height: 0.05em;
  position: absolute;
  right: 0;
  width: 100%;
}
.underline.bottom:after {
  background: -moz-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: -webkit-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: -ms-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  border-radius: 0.2em;
  bottom: 0;
  content: "";
  height: 0.05em;
  position: absolute;
  right: 0;
  width: 100%;
}

.scroll::-webkit-scrollbar,
.overflow-auto::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll::-webkit-scrollbar-track,
.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(234, 212, 210, 0.8);
  border-radius: 8px;
}

.scroll::-webkit-scrollbar-thumb,
.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(234, 212, 210, 1);
}

.white.scroll::-webkit-scrollbar-track,
.white.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.4);
  border-radius: 8px;
}

.white.scroll::-webkit-scrollbar-thumb,
.white.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
}

.logo {
  max-width: 250px;
  width: 100%;
  height: auto;
}
.logo.home {
  max-width: 300px;
  width: 100%;
  height: auto;
}
.logo.side {
  width: 30%;
  height: auto;
}

#topNavBtn {
  border: none;
}

#topNavBtn:focus,
a:focus,
button:focus,
.custom-control-label::before {
  outline: 0 !important;
  box-shadow: none !important;
}

.small-nav {
  display: none;
}
.big-nav {
  display: block;
}
.additional-nav {
  position: absolute;
  right: 0.8em;
  top: 2em;
}

.slogan {
  position: absolute;
  text-align: right;
  font-size: 80%;
  font-style: italic;
  line-height: 1em;
  bottom: 12%;
  left: 29%;
  width: 18%;
}
.home .slogan {
  position: absolute;
  text-align: right;
  font-size: 90%;
  line-height: 1em;
  bottom: 14%;
  left: 0%;
  width: 50%;
}
.modal.show {
  -moz-transition: opacity 160ms ease-out;
  -webkit-transition: opacity 160ms ease-out;
  -ms-transition: opacity 160ms ease-out;
  -o-transition: opacity 160ms ease-out;
  transition: opacity 160ms ease-out;
}
.modal-content-slide-down {
  animation-name: animatetop;
  animation-duration: 0.1s;
}
.modal-large {
  max-width: 85% !important;
}
.remove-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.check-btn {
  position: absolute;
  top: 0;
  left: 0;
}
.awaiting {
  pointer-events: none;
  opacity: 0.3;
}

.developed-by {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -moz-transition: opacity 250ms ease-out;
  -webkit-transition: opacity 250ms ease-out;
  -ms-transition: opacity 250ms ease-out;
  -o-transition: opacity 250ms ease-out;
  transition: opacity 250ms ease-out;
}
.left-slide {
  animation: 2.5s ease-out 0s 1 slideInFromLeft;
}
.right-slide {
  animation: 2.5s ease-out 0s 1 slideInFromRight;
}
.bottom-slide1 {
  animation: 2s ease-out 0s 1 slideInFromBottom1;
}
.bottom-slide2 {
  animation: 2.5s ease-out 0s 1 slideInFromBottom2;
}
.fade-in {
  opacity: 0;
  animation: 1s linear 1 fade-in;
  animation-fill-mode: forwards;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@media (min-height: 1000px) {
  .bg-image.height {
    height: 75vh;
  }
  .bg-image.h-albums {
    height: 53vh;
  }
}
@media (max-height: 1000px) {
  .bg-image.height {
    height: 72vh;
  }
  .bg-image.h-albums {
    height: 51vh;
  }
}
@media (max-height: 900px) {
  .bg-image.height {
    height: 68vh;
  }
}
@media (max-height: 800px) {
  .bg-image.height {
    height: 65vh;
  }
}
@media (min-width: 1500px) {
  .container,
  .container-md {
    max-width: 80% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-85 {
    width: 85%;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .modal-large {
    margin-left: auto;
    margin-right: auto;
    max-width: 90% !important;
  }
}
@media (min-width: 768px) {
  .w-md-75 {
    width: 75%;
  }
  .w-md-50 {
    width: 50%;
  }
  .fixed-top-lg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .bg-image.bottom-pos {
    background-position: bottom;
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-40%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(40%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInFromBottom1 {
    0% {
      transform: translateY(80%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slideInFromBottom2 {
    0% {
      transform: translateY(130%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
@media (max-width: 1499px) {
  .page {
    margin: 180px auto 16px auto;
    min-height: 85vh;
  }
  .logo {
    max-width: 220px;
  }
  .slogan {
    font-size: 70%;
    bottom: 12%;
    left: 27%;
    width: 20%;
  }
}
@media (max-width: 1200px) {
  .slogan {
    bottom: 12%;
    left: 21%;
    width: 25%;
  }
  .home .slogan {
    bottom: 14%;
    left: 0%;
    width: 59%;
  }
}
@media (max-width: 992px) {
  .w-lgx-75 {
    width: 75%;
  }
  .w-lgx-50 {
    width: 50%;
  }
  .slogan {
    bottom: 12%;
    left: 4%;
    width: 40%;
  }
  .home .slogan {
    font-size: 70%;
    bottom: 14%;
    left: 0%;
    width: 65%;
  }
}

@media (max-width: 767px) {
  .w-mdx-100 {
    width: 100%;
  }
  .w-mdx-75 {
    width: 75%;
  }
  .w-mdx-50 {
    width: 50%;
  }
  .page {
    margin: 20px auto 16px auto;
  }
  .modal-large {
    max-width: calc(100% - 16px) !important;
  }
  .navbar {
    padding: 0;
  }
  .logo-nav {
    vertical-align: center;
    min-height: 140px;
  }
  .logo,
  .logo.home {
    max-width: 160px;
    width: 100%;
    height: auto;
  }
  .small-nav {
    display: block;
    z-index: 100000;
  }
  .big-nav {
    display: none;
  }

  #topNav {
    background-color: #fcf8f7;
    border-top: solid 1px rgba(255, 255, 255, 0.5);
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    position: absolute;
    display: none;
    width: 100%;
    top: 140px;
    left: 0;
    z-index: 1000;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 1.5em;
    height: 0.25em;
    line-height: 0.25em;
    background-color: #87163e;
    margin: 0.25em 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }

  .change .bar1 {
    -moz-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -webkit-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -ms-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -o-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -moz-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -webkit-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -ms-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -o-transform: rotate(45deg) translate(-0.375em, -0.375em);
    transform: rotate(45deg) translate(-0.375em, -0.375em);
  }
  .additional-nav {
    right: 1em;
    top: 1.5em;
  }
  .slogan {
    bottom: 15%;
    right: 2em;
    width: 50%;
  }
  .home .slogan {
    font-size: 60%;
    bottom: 14%;
    left: -7%;
    width: 40%;
  }
  .fade-in {
    opacity: 1;
  }
  .developed-by {
    position: relative;
    margin-top: 16px;
  }
  .bg-image.top-pos {
    background-position: 50% 10%;
  }
}
@media (max-width: 575px) {
  .w-smx-100 {
    width: 100% !important;
  }
  .bg-image.height {
    height: 50vh;
  }
  .logo,
  .logo.home {
    max-width: 150px;
  }
  .social-btns {
    flex: inline-block;
  }
  .slogan {
    flex: none;
  }
}

@media (max-width: 420px) {
  .social-btns {
    display: none;
  }
  .bg-image.height {
    height: 30vh;
  }
}
@media (max-width: 290px) {
  .additional-nav {
    right: 0.2em;
    top: 0.5em;
  }
}
@media (max-width: 210px) {
  #topNav {
    top: 100px;
  }
}
